<template>
  <v-app>
    <app-navbar />
    <app-sidebar />
    <v-content>
      <v-container fluid>
        <app-loader />
        <router-view />
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import AppNavbar from 'components/layout/navbar';
import AppSidebar from 'components/layout/sidebar';

export default {
  name: 'MainLayout',
  components: {
    AppNavbar,
    AppSidebar
  }
};
</script>
